import { ethers, BigNumber } from 'ethers';
import { erc721ABI, contractAddress } from './contractInfo';
import bnhABI from './brand_name_here_nft_transfer';
import erc20ABI from './erc20ABI';


// check if there is an injected web3 provider
// if there is, request the user to connect.
// Then set the window.web3 object to the injected provider
export const connect = async () => {
    console.log("connecting...");
    if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const { provider, signer } = await getProvider();
        const address = await signer.getAddress();
        window.web3 = {
            provider: provider,
            signer: signer,
            address: address,
            connected: true
        }
        //setupWeb3Listeners();
        if (window.web3) {
            return {success: true, message: "Connected to Ethereum"};
        } else {
            return {success: false, message: "User denied access to Ethereum"};
        }

    } else {
        return {success: false, message: "No wallet"};
    }
}

// check if the user is still connected to the injected web3 provider
export const checkConnection = async () => {
    console.log("checking connection...");
    if (web3Exists()) {
        const { provider, signer } = await getProvider();
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
            const address = await signer.getAddress();
            window.web3 = {
                provider: provider,
                signer: signer,
                address: formatLongAddress(address),
                connected: true
            }
            return {success: true, message: "Connected to Ethereum"};
        } else {
            window.web3 = {
                provider: null,
                signer: null,
                address: '',
                connected: false
            }
            return {success: false, message: "Not connected to Ethereum"};
        }
    }
    return {success: false, message: "No web3 provider"};
}

// setup a listener for the injected web3 provider
export const setupWeb3Listener = (_listener, _callback) => {
    console.log("setting up web3 listener...");
    switch (_listener) {
        case 'accountsChanged':
            window.ethereum.on('accountsChanged', function (accounts) {
                console.log("accounts changed");
                console.log('account0 ',accounts[0]);
                window.web3.address = formatLongAddress(accounts[0]);
                if (!accounts[0]) {
                    window.web3 = {
                        provider: null,
                        signer: null,
                        address: '',
                        connected: false
                    }
                    _callback({connected: false, message: "Not connected to Ethereum"});
                } else {
                    _callback({connected: true, message: "Connected to Ethereum", address: accounts[0]});
                }
            });
            break;

        case 'chainChanged':
            window.ethereum.on('chainChanged', function (chainId) {
                console.log("chain changed");
                // Time to reload your interface with the new chainId
                console.log('chainId ',chainId);
                _callback();
            });
            break;
        /*
        case 'Approval':
            window.ethereum.on('Approval', function (owner, spender, value) {
                console.log("approval event fired");
                console.log(owner, spender, value);
                //_callback({owner, spender, value}, "Approval");
            });
            break;
        case 'Receipt':
            window.ethereum.on('Receipt', function (seller, nftAddress, nftTokenId, saleToken, salePrice) {
                console.log("receipt event fired");
                console.log(seller, nftAddress, nftTokenId, saleToken, salePrice);
                //_callback({seller, nftAddress, nftTokenId, saleToken, salePrice}, "Receipt")
            });
            break;
            */
    }
}

// write method to get and return provider, signer
export const getProvider = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    return {provider: provider, signer: signer};
}

export const generateNonce = () => {
    let nonce = BigNumber.from(ethers.utils.randomBytes(4));
    return nonce;
}

export const web3Exists = () => {
    if (window.web3) {
        return true;
    } else {
        return false;
    }
}

export const shortAddress = (address) => {
    return address.substring(0, 12) + "..." + address.substring(address.length - 10, address.length);
}

export const isValidNftAddress = async (_nft_address) => {
    let nftAddressRegex = /^[a-fA-F0-9]{40}$/;
    let isValidNftAddress = nftAddressRegex.test(_nft_address);
    if (isValidNftAddress) {
        return true;
    }
    return false;
}

export const formatAddress = (_address) => {
    // check if address contains a 0x at the beginning
    if (_address) {
        let address = shortAddress(ethers.utils.getAddress(_address));
        return address;
    } else {
        return "";
    }
}

export const formatLongAddress = (_address) => {
    let address = ethers.utils.getAddress(_address);
    return address;
}

export const getTokenOwner = async (_contract_address, _token_id) => {
    console.log("getting token owner...");
    console.log(_token_id);
    const { provider, signer } = await getProvider();
    const contract = new ethers.Contract(`${_contract_address}`, erc721ABI, provider);
    let owner = await contract.ownerOf(BigNumber.from(_token_id));
    console.log("owner: ", owner);
    return owner;
}




export const attestOwnership = async (_contract_address, _token_id) => {
    const { provider, signer } = await getProvider();
    let address = await signer.getAddress();
    //address = "0x7FF40b10781E5E6A05f8f33a1478064a44cCB5a9" // TODO: < remove this setting of address
    let res = {success: false, message: "Error signing message"};
    const owner = await getTokenOwner(_contract_address, _token_id);
    if (owner !== address) {
        res.success = false;
        res.message = "You are not the owner of this token";
        res.signatures = null;
        return res;
    } else {
        const msgParams = await createMessageParams(_contract_address, _token_id, owner);
        try {
            // TODO: try replacing this with an ethereum method
            let signature = await signer._signTypedData(msgParams.domain, msgParams.types, msgParams.message);
            console.log("signature: ", signature);
            res.success = true;
            res.message = "Successfully signed message";
            res.signature = signature
            return res;
        } catch (error) {
            res.success = false;
            res.message = error.message;
            res.signature = null;
            console.log(error);
            return res;
        }
    }


}
export const verifyOwner = async (_contract_address, _token_id, _signature) => {
    console.log("verifying owner...");
    const owner = await getTokenOwner(_contract_address, _token_id);

    let res = {success: false, message: "Error verifying ownership"};
    // use ethers to recover the signer address from the signature
    let messageData = await createMessageParams(_contract_address, _token_id, owner);
    console.log(messageData);
    try {
        let recoveredAddress = ethers.utils.verifyTypedData(messageData.domain, messageData.types, messageData.message, _signature);
        console.log("recovered address: ", recoveredAddress);
        console.log("original address: ", owner);
        if (recoveredAddress === owner) {
            res.success = true;
            res.message = "Successfully verified ownership";
            res.owner = recoveredAddress;
            return res;
        } else {
            res.success = false;
            res.message = "Failed to verify ownership";
            res.owner = null;
            return res;
        }
    } catch (error) {
        res.success = false;
        res.message = error.message;
        res.owner = null;
        console.log(error);
        return res;
    }

}

export const signSellOrder = async (_data) => {
    const { provider, signer } = await getProvider();
    console.log("signing sell order...");
    console.log(_data);
    // write a method to sign the sell order message
    let address = await signer.getAddress();
    //address = "0x7FF40b10781E5E6A05f8f33a1478064a44cCB5a9" // TODO: < remove this setting of address
    let res = {success: false, message: "Error signing message"};
    const owner = await getTokenOwner(_data.nftAddress, _data.tokenId);
    if (owner !== address) {
        res.success = false;
        res.message = "You are not the owner of this token";
        res.signatures = null;
        return res;
    } else {
        // needs chainId nftAddress owner, tokenId saleprice, saletoken, nonce
        const msgParams = await createSellOrderMessageParams({
            recipient: _data.recipient,
            nftAddress: _data.nftAddress,
            tokenId: _data.tokenId,
            salePrice: _data.salePrice,
            saleToken: _data.saleToken,
            nonce: _data.nonce
        });
        
        try {

            const signature = await window.ethereum.request({
                method: 'eth_signTypedData_v4',
                params: [address, JSON.stringify(msgParams)],
              });

            res.success = true;
            res.message = "Successfully signed message";
            res.signature = signature
            return res;
        } catch (err) {
            console.error(err);
            res.success = false;
            res.message = err.message;
            res.signature = null;
            return res;
        }
    }
}

const approve721 = async (_contract_address, _token_id) => { // < nft contract address, nft token id
    const { provider, signer } = await getProvider();
    let address = await signer.getAddress();
    try {
    //address
        const erc721contract = new ethers.Contract(_contract_address, erc721ABI, provider);
    
        let res = await erc721contract.approve(_contract_address, _token_id);
        console.log(res);
        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
}

// TODO: check for approval status and don't re-approve if not necessary
// check for approval call already existing
export const approveTokenERC721 = async (_data, _callback) => {
    console.log("approving token...");
    console.log(_data);
    const { provider, signer } = await getProvider();
    const erc721Contract = new ethers.Contract(_data.nftAddress, erc721ABI, provider);
    // add web3 event listener to listen for the acceptSellOrder event
    // event Approval(address indexed owner, address indexed spender, uint256 value)
    
    try {
        // set state to pending on approvie until approved.
        // any other button should be unclickable or not there/disabled
        await erc721Contract.connect(signer).approve(contractAddress, _data.nftTokenId);
        
        erc721Contract.on("Approval", (owner, approved, tokenId) => {
            console.log("approval event fired");
            console.log(owner, approved, tokenId);
            // TODO: state should be loading until this event is captured,
            // once it's captured, this should fire off the next metamask signature request
            _callback({owner, approved, tokenId}, "Approval");
        });
        return {
            success: true,
            message: "Successfully requested erc721 token approval signature"
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: error.message
        }
    }
};

// TODO: check for approval status and don't re-approve if not necessary
// check for approval call already existing
export const approveToken = async (_data, _callback) => {
    console.log("approving token...");
    console.log(_data);
    const { provider, signer } = await getProvider();
    const erc20Contract = new ethers.Contract(_data.saleToken, erc20ABI, provider);
    // add web3 event listener to listen for the acceptSellOrder event
    // event Approval(address indexed owner, address indexed spender, uint256 value)
    
    try {
        // set state to pending on approvie until approved.
        // any other button should be unclickable or not there/disabled
        await erc20Contract.connect(signer).approve(contractAddress, _data.salePrice + 9);
        erc20Contract.on("Approval", (owner, spender, value) => {
            console.log("approval event fired");
            console.log(owner, spender, value);
            // TODO: state should be loading until this event is captured,
            // once it's captured, this should fire off the next metamask signature request
            _callback({owner, spender, value}, "Approval");
        });
        return {
            success: true,
            message: "Successfully approved token"
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: error.message
        }
    }
};

export const acceptSellOrder = async (_data, _callback) => {
    console.log("accepting sell order...");
    console.log(_data);
    const { provider, signer } = await getProvider();
    const contract = new ethers.Contract(contractAddress, bnhABI.abi, provider);
    const erc20Contract = new ethers.Contract(_data.saleToken, erc20ABI, provider);
    // add web3 event listener to listen for the acceptSellOrder event
    // event Approval(address indexed owner, address indexed spender, uint256 value)
    /*erc20Contract.on("Approval", (owner, spender, value) => {
        console.log("approval event fired");
        console.log(owner, spender, value);
        _callback({owner, spender, value}, "Approval");
    });*/
    try {
        console.log("attempting sale");
        // set state to pending on approvie until approved.
        // any other button should be unclickable or not there/disabled
       // await erc20Contract.connect(signer).approve(contractAddress, _data.salePrice + 9);
       var sellOrder = {
            recipient: _data.recipient, // < buyers address || 0x0000000000000000000000000000000000000000
            nftAddress: _data.nftAddress,
            nftTokenId: BigNumber.from(_data.nftTokenId),
            saleToken: _data.saleToken,
            salePrice: _data.salePrice,
            nonce: _data.nonce,
        }
        await contract.connect(signer).purchase(
            sellOrder,
            _data.signedListing
        );

        console.log("got passed the purchase call");
        contract.on("Receipt", (buyer, nftAddress, nftTokenId, seller, saleToken, salePrice) => {
            console.log("receipt event fired");
            console.log(buyer, nftAddress, nftTokenId, seller, saleToken, salePrice);
            _callback({buyer, nftAddress, nftTokenId, seller, saleToken, salePrice}, "Receipt")
        });
        return {
            success: true,
            message: "Successfully made metamask signature request"
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: error.message
        }
    }
    
    
};



// TODO: implement this in
async function createSellOrderMessageParams(_data) {
    const { provider, signer } = await getProvider();



    const EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ];
    const SellOrder = [
        { name: 'recipient', type: 'address' },
        { name: 'nftAddress', type: 'address' },
        { name: 'nftTokenId', type: 'uint256' },
        { name: 'saleToken', type: 'address' },
        { name: 'salePrice', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
    ]

    const name = "Material Market";
    const version = "1";
    const chainId = (await provider.getNetwork()).chainId;
    const verifyingContract = contractAddress;
    const message = {
        recipient: _data.recipient,
        nftAddress: _data.nftAddress,
        nftTokenId: _data.tokenId,
        saleToken: _data.saleToken,
        salePrice: _data.salePrice,
        nonce: _data.nonce//new Date().getTime() DIFF TODO: change back to timestamp (should be created when sell order is signed)
    }
    const data = {
        types: {
            EIP712Domain,
            SellOrder
        },
        domain: { name, version, chainId, verifyingContract },
        primaryType: 'SellOrder',
        message,
    };

    console.log("msgParams: ", data);
    return data;
}



// TODO: implement this in 
async function oldCreateSellOrderMessageParams(_data) {
    const { provider, signer } = await getProvider();
    console.log("creating sell order message params...");
    console.log((await provider.getNetwork()).chainId);
    console.log(_data);
    const msgParams = {
        types: {
            EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
            SellOrder: [
                /*{ name: 'contents', type: 'string' },*/
                { name: 'recipient', type: 'address' },
                { name: 'nftAddress', type: 'address' },
                { name: 'nftTokenId', type: 'uint256' },
                { name: 'saleToken', type: 'address' },
                { name: 'salePrice', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
            ],
        },

        domain: {
            // Give a user friendly name to the specific contract you are signing for.
            name: 'Material Market',
            // Just let's you know the latest version. Definitely make sure the field name is correct.
            version: '1',
            // Defining the chain aka Rinkeby testnet or Ethereum Main Net
            chainId: (await provider.getNetwork()).chainId, // TODO: chainID should be dynamic
            // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
            verifyingContract: contractAddress,
        },
        
        // Refers to the keys of the *types* object below.
        primaryType: 'SellOrder',

        // Defining the message signing data content.
        message: {
            /*
            - Anything you want. Just a JSON Blob that encodes the data you want to send
            - No required fields
            - This is DApp Specific
            - Be as explicit as possible when building out the message schema.
            */

            /*
            TODO: ask james if contents is required
            contents: "Initiate Sale",  //'Proof that \n' + _data.owner + '\n intends to sell token ' + _data.tokenId + '\n belonging to the contract address \n' + _data.nftAddress,
            */
            
           recipient: _data.recipient,
           nftAddress: _data.nftAddress,
           nftTokenId: _data.tokenId,
           saleToken: _data.saleToken,
           salePrice: _data.salePrice,
           nonce: _data.nonce,
        },
        
        
    };
    console.log("msgParams: ", msgParams);
    return msgParams;
}


async function createMessageParams(_nft_address, _token_id, _owner) {
    const { provider, signer } = await getProvider();
    const msgParams = {
        domain: {
            // Give a user friendly name to the specific contract you are signing for.
            name: 'Ownership',
            // Just let's you know the latest version. Definitely make sure the field name is correct.
            version: '1',
            // Defining the chain aka Rinkeby testnet or Ethereum Main Net
            chainId: (await provider.getNetwork()).chainId, // TODO: chainID should be dynamic
            // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
            verifyingContract: _nft_address,
        },

        // Defining the message signing data content.
        message: {
          /*
           - Anything you want. Just a JSON Blob that encodes the data you want to send
           - No required fields
           - This is DApp Specific
           - Be as explicit as possible when building out the message schema.
          */
          contents: 'Proof that ' + _owner + ' owns ' + _token_id + ' at address ' + _nft_address,
          owner: _owner,
          token: {
            nft_address: _nft_address,
            token_id: parseInt(_token_id)
          },
        },
        // Refers to the keys of the *types* object below.
        primaryType: 'Ownership',
        types: {
          Ownership: [
            { name: 'contents', type: 'string' },
            { name: 'owner', type: 'address' },
            { name: 'token', type: 'Token' },
          ],
          Token: [
            { name: 'nft_address', type: 'address' },
            { name: 'token_id', type: 'uint256' },
          ],
        },
      };
      return {domain: msgParams.domain, types: msgParams.types, message: msgParams.message};
}




export async function getTokenUri(_address, _token_id, _item_type) {
    const { provider, signer } = await getProvider();
    let address = await signer.getAddress();
    //handle ENS
    if(_address == "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85"){
      return "https://metadata.ens.domains/mainnet/0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/" + _token_id;
    }
  
    // hashmasks
    if(_address == "0xC2C747E0F7004F9E8817Db2ca4997657a7746928") {
      return "https://hashmap.azurewebsites.net/getMask/" + _token_id;
    }
  
    let nft_contract = new ethers.Contract(_address, erc721ABI, provider);
  
    //Opensea contract handler
    if(_address == "0x495f947276749Ce646f68AC8c248420045cb7b5e") {
      return (await nft_contract.uri(_token_id)).replace('ipfs://','https://cloudflare-ipfs.com/ipfs/').replace('0x{id}', _token_id);
    }
  
    switch (_item_type) {
      case 2:
        try {
          return (await nft_contract.tokenURI(_token_id))
            .replace('ipfs://','https://cloudflare-ipfs.com/ipfs/')
            .replace("https://ipfs.io/ipfs/",'https://cloudflare-ipfs.com/ipfs/')
            .replace(/[a-z0-9]*\.mypinata\.cloud/, "cloudflare-ipfs.com")
            .replace(/[a-z0-9]*\.pinata\.cloud/, "cloudflare-ipfs.com");
        } catch {
          console.log("BROKEN TOKEN URI");
        }
        try {
          return (await nft_contract.baseURI()).replace('ipfs://','https://cloudflare-ipfs.com/ipfs/') + _token_id.toString();
        } catch {
          console.log("BROKEN BASE URI");
        }
        return "BROKEN"
        break;
      case 3:
        //Lazy Minter
        _address = _address ==  "0xA604060890923Ff400e8c6f5290461A83AEDACec" ? "0x495f947276749Ce646f68AC8c248420045cb7b5e" : _address;
        let filter = nft_contract.filters.URI(null,_token_id);
        let res = await nft_contract.queryFilter( filter, 0, 'latest');
        if(res.length != 0) {
          return res[0].args._value.replace('ipfs://','https://cloudflare-ipfs.com/ipfs/');
        }
        return await nft_contract.uri(_token_id).replace('ipfs://','https://cloudflare-ipfs.com/ipfs/')
        break;
      default:
    }
  }
  
  
  
  export async function getImageUri(_token_uri) {
    console.log("token uri: ", _token_uri);
    if(!_token_uri) return "NO TOKEN URI?";
    if (_token_uri.substring(0,4) == "data") {
      console.log(" This one crashed it ",_token_uri);
      console.log("TRUE?", _token_uri.startsWith("data:application/json;base64,"));
      if (_token_uri.startsWith("data:application/json;base64,"))
        return JSON.parse(atob(_token_uri.replace('data:application/json;base64,', '')).replace("},]}", "}]}")).image;
      if (_token_uri.startsWith("data:application/json,{"))
        return JSON.parse(_token_uri.replace('data:application/json,', '').replace("},]}", "}]}")).image;
    } else {
      try {
        //var res = await axios.get(_token_uri);
        // create a fetch request
        var res = await (await fetch(_token_uri)).json();
        //console.log("res", res);
        var imageRawUrl = res.image || res.image_data || "none";
        var imageUrl = imageRawUrl
        .replace('ipfs://','https://cloudflare-ipfs.com/ipfs/')
        .replace("https://ipfs.io/ipfs/",'https://cloudflare-ipfs.com/ipfs/')
        .replace(/[a-z0-9]*\.mypinata\.cloud/, "cloudflare-ipfs.com")
        .replace(/[a-z0-9]*\.pinata\.cloud/, "cloudflare-ipfs.com");

        //console.log(res);
        return {
            name: res.name,
            image: imageUrl,
            description: res.description
        }
      } catch (e) {
        console.log(e);
        return;
      }
    }
  }