export const contractAddress = "0x034766Fb8eA488a7400fb830a901295b5200f2d0";
export const erc721ABI = [
  "function ownerOf(uint256) external view returns(address)",
  "function tokenURI(uint256 _tokenId) external view returns(string memory)",
  "function baseURI() external view returns(string memory)",
  "function uri(uint256 _tokenId) external view returns(string memory)",
  "function approve(address to, uint256 tokenId) external",
  "event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId)",
  "event URI(string _value, uint256 indexed _id)",
  // TODO: JAMES add events
]