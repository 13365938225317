import React from 'react';
import { Message } from '../components/pageComponents';

function Splash() {
    let infoText = [
        "tools are under construction!",
    ]
    return (
      <div style={{ padding: 20 }}>
        <h1 className="app-header">NFT DataLink</h1>
        <Message className="splash-text" type="warning" text={"tools are under construction!"} />
      </div>
    );
  }

export default Splash;