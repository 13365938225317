import logo from './logo.svg';
import './App.css';
import { connect, checkConnection, setupWeb3Listener, web3Exists, shortAddress, attestOwnership, verifyOwner } from './utils/web3Handler';
//import Web3Component from './components/web3Component';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import { Attest, Verify, Home, Sale, Buy, Splash } from './pages/index.js';
import { ComponentWrapper, PageContainer, Message } from './components/pageComponents';
import React from 'react';
function App() {
  const [isConnected, setIsConnected] = React.useState(false);
  const [isWeb3User, setIsWeb3User] = React.useState(false);
  const [address, setAddress] = React.useState("");
  React.useEffect(() => {
    async function action() {
      let res = await checkConnection();
      if (res.success) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    }
    action();
  }, []);
  const handleWeb3Change = (res) => {
    setAddress(res.address);
    console.log("address changed", res.address);
  }
  React.useEffect(() => {
    if (web3Exists()) {
        setIsWeb3User(true);
        setupWeb3Listener("accountsChanged", handleWeb3Change);
    } else {
        setIsWeb3User(false);
    }
  }, []);

  const handleConnect = async (e) => {
    console.log("clicked");
    let res = await connect();
    setIsConnected(res.success);
  }

  
  return (
    <div className="app-container">
      <Splash />
    </div>
  )
  // if web3 is connected, return the routes, otherwise, return the connect button.
  if (isConnected) {
    // display the routes
    return (
      <Router>
        <div className="app-container">
              <Routes>
                    <Route path = "/" element = {<Attest />} exact></Route>
                    <Route path = "/:n" element = {<Attest />} exact></Route>
                    <Route path = "/:n/:t" element = {<Attest />} exact></Route>
                    <Route path = "/attest/:n/:t" element = {<Attest />}></Route>
                    <Route path = "/verify/:n/:t/:s" element = {<Verify />}></Route>
                    <Route path = "/sale/:n/:t/" element = {<Sale />}></Route>
                    <Route path = "/buy/:n/:t/:s/:recipient/:saleToken/:salePrice/:nonce" element = {<Buy />}></Route>
                    <Route path = "*" element = {
                      <Message small type="warning" text="404 Page Not Found"></Message>
                    }></Route>
              </Routes>
        </div>
      </Router>
    );
  } else if (isWeb3User) {
    // display the connect button
    return (
        <div className="app-container">
          <ComponentWrapper>
            <div className="grid-item button-group">
            <Message type="warning" small text="Connect your wallet to continue" />
              <button onClick={handleConnect}>Connect Wallet</button>
            </div>
          </ComponentWrapper>
        </div>
    );
  } else {
    // display the get metamask message
    return (
      <div className="app-container">
        <div className="grid-item button-group">
          <Message type="warning" small text="You must have an injected web3 provider." />
          <a id="get-metamask-link" href="https://metamask.io/download.html" target="_blank" rel="noreferrer">Get Metamask</a>
        </div>
      </div>
    );
  }


}

export default App;
