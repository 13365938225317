import React from 'react';
import { Button, Header, Image, Modal, Form } from 'semantic-ui-react'
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { BiMessageAltError, BiCommentError, BiCommentX, BiChevronRight } from 'react-icons/bi';
import { FiCheckSquare, FiXSquare, FiAlertTriangle } from 'react-icons/fi';
import { formatAddress } from '../utils/web3Handler';

export const PageWrapper = ({ title, children }) => {
    return (
        <>
            <h1 class="app-header">{title}</h1>
            <ComponentWrapper>
                {children}
            </ComponentWrapper>
        </>
    );

}

export const ComponentWrapper = ({children}) => {
    return (
        <div className="component-wrapper">
            {children}
        </div>
    )
}

export const Notice = ({children}) => {
    return (
        <div className="notice">
            {children}
        </div>
    )
}

export const DataComponent = ({data}) => {
    const [address, setAddress] = React.useState(data.connectedAddress);
    React.useEffect(() => {
        console.log("address changed", window.web3.address);
        setAddress(data.connectedAddress);
    }, []);
    return (
        <table>
            <tbody>
                <tr>
                    <td>Connected Address</td>
                    <td>{formatAddress(window.web3.address)}</td>
                </tr>
                <tr>
                    <td>Token Owner</td>
                    <td>{data.tokenOwner}</td>
                </tr>
                <tr>
                    <td>Token ID</td>
                    <td>{data.tokenId}</td>
                </tr>
                <tr>
                    <td>NFT Address</td>
                    <td>{data.nftAddress}</td>
                </tr>

            </tbody>
        </table>
    )
}

export const PurchaseItemInfoComponent = ({data}) => {
    const [address, setAddress] = React.useState(data.connectedAddress);
    const date = new Date(data.timestamp);
    React.useEffect(() => {
        console.log("address changed", window.web3.address);
        setAddress(data.connectedAddress);
    }, []);
    return (
        <table>
            <tbody>
                <tr>
                    <td>Sell Order Issued at</td>
                    <td>{date.toString("MMM dd")}</td>
                </tr>
                <tr>
                    <td>Price</td>
                    <td>{data.price}</td>
                </tr>
                <tr>
                    <td>Payment Token</td>
                    <td>{data.paymentToken.label}</td>
                </tr>

            </tbody>
        </table>
    )
}

export const NFTDataComponent = ({data, overlay}) => {
    if (!data) {
        return (
            <div>
                <h3>Loading</h3>
            </div>
        )
    } else {
        return (
            <>
                <h3 className="nft-name">{data.name}</h3>
                <img src={data.image} alt="NFT" />
                <div className="nft-description">{data.description}</div>
            </>
        )
    }
}

  export const PageContainer = (props) => {
    var eSubject = 'Hi, I would like to verify this NFT. Please use this verification link.'
    var eBody = 'Some content to the email...'
    let emailLink = `${`mailto:?target=_blank&subject=${encodeURIComponent(eSubject)}&body=${encodeURIComponent(eBody)}`}`;
    let phoneLink = `${`sms:?target=_blank&subject=${encodeURIComponent(eSubject)}&body=${encodeURIComponent(eBody)}`}`
    //console.log('props: ', props);
    return (
        <PageWrapper title={props.title}>
            <div className="header">
                <DataComponent data={{verifier: formatAddress(window.web3.address), tokenOwner: formatAddress(props.tokenOwner), tokenId: props.tokenId, nftAddress: formatAddress(props.nftAddress)}} />
            </div>
            
            <div className="grid-item nft-data-container">
                <NFTDataComponent data={props.imageUri} />
            </div>
            <div className='grid-item extra-padding'>
                
                {
                    /*if status, generate here*/
                    props.status && 
                    <div className="grid-item nft-data-container">
                        <Message type={props.status} text={props.statusText} />
                    </div>
                }

                {
                    /*if qr, generate here*/
                    props.qrCode &&
                    <div key={`${props.qrCode}-qr`} className="grid-item qr-container">
                        <img id="attestation-qr-code" src={props.qrCode} alt="img here" />
                    </div>
                }

                {
                    /*if send buttons, generate here*/
                    props.sendButtons &&
                    <div className="grid-item send-buttons-container">
                        <a className='web3-button' href={emailLink}><FaEnvelope /></a>
                        <a className='web3-button' href={phoneLink}><FaPhone /></a>
                    </div>
                }

                {
                    props.attestOwnership &&
                    <div className="grid-item button-group">
                        <button className="web3-button" onClick={props.handleClick}>Attest Ownership</button>
                    </div>
                }

                {
                    props.verifyOwnership &&
                    <Message small type={'info'} text={'The ownership of this NFT is being verified.'} />
                }
                
                
            </div>

            {
                /*if sell order, add here*/
                props.sellOrder &&
                <div className="grid-item button-group">
                    <button className="web3-button" onClick={props.handleClick}>Sign Sell Order</button>
                </div>
            }
        </PageWrapper>
    )
}


export const Message = (props) => {
    switch (props.type) {
        case "error":
            return (
                <div className={`web3-message ${props.type} ${props.small ? 'small' : ''}`}>
                    <BiCommentX size="xs" />
                    <p>{props.text}</p>
                    {props.text2 && <p className="small-text">{props.text2}</p>}
                    {props.children}
                </div>
            )
        case "warning":
            return (
                <div className={`web3-message ${props.type} ${props.small ? 'small' : ''}`}>
                    <BiCommentError size="xs" />
                    <p>{props.text}</p>
                    {props.text2 && <p className="small-text">{props.text2}</p>}
                    {props.children}
                </div>
            )
        case "info":
            return (
                <div className={`web3-message ${props.type} ${props.small ? 'small' : ''}`}>
                    <BiCommentError size="xs" />
                    <p>{props.text}</p>
                    {props.text2 && <p className="small-text">{props.text2}</p>}
                    {props.children}
                </div>
            )
        case "success":
            return (
                <div className={`web3-message ${props.type} ${props.small ? 'small' : ''}`}>
                    <FiCheckSquare size="xs" />
                    <p>{props.text}</p>
                    {props.text2 && <p className="small-text">{props.text2}</p>}
                    {props.children}
                </div>
            )
        
    
        default:
            break;
    }
}
